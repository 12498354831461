<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        <a
          href="https://docs.google.com/document/d/e/2PACX-1vQmipIPg4EQxCFPEEMTNQ9bgg1tRciAEHbEe4M3IsN7mF65BGg7xvZ9ytgj-mFmtz2LUWJEZyaUsNNi/pub"
          rel="noopener noreferrer"
          target="_blank"
        >
          Error
        </a>
        is part of making experimental measurements. Match the following experimental issues to a
        type of error.
        <a
          href="https://docs.google.com/document/d/e/2PACX-1vQmipIPg4EQxCFPEEMTNQ9bgg1tRciAEHbEe4M3IsN7mF65BGg7xvZ9ytgj-mFmtz2LUWJEZyaUsNNi/pub"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here for more info about error analysis </a
        >.
      </p>

      <v-simple-table width="100%">
        <tr v-for="letter in letterOptions" :key="letter.inputName">
          <td>
            {{ letter.text }}
          </td>
          <td>
            <v-select
              v-model="inputs[letter.inputName]"
              style="display: inline-block"
              class="my-0 py-0"
              :items="optionsShuffled"
              item-text="text"
              item-value="value"
              label="Select an option:"
            >
              <template #item="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.text" />
              </template>
            </v-select>
          </td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI1LC_Q4S2_Q5',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options: [
        {text: 'Light source on the visible spectrometer dims over time', value: 'option1'},
        {
          text: 'Eliminated by good experimental methods & more than 1 calibration step',
          value: 'option2',
        },
        {text: 'Causes outlier that can be determined by the "Q-Test"', value: 'option3'},
        {text: 'Measured by calculating estimated standard deviation', value: 'option4'},
        {
          text: 'Using a graduated cylinder instead of volumetric glassware to make standard solutions',
          value: 'option5',
        },
      ],
      letterOptions: [
        {text: 'a) Random Error', inputName: 'input1'},
        {text: 'b) Instrumental Error', inputName: 'input2'},
        {text: 'c) Methodological Error', inputName: 'input3'},
        {text: 'd) Systematic Error', inputName: 'input4'},
        {text: 'e) Gross Error', inputName: 'input5'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
